<template>
    <div>
        <div class="modal-title">Edit Integration Partners</div>
        <div class="flex flex-1 h-full flex-col px-4">
            <div class="form-row">
                <div class="form-col">
                    <label for="recapcha">Google recapcha id</label>
                    <text-input id="recapcha" v-model="integrations.recapcha_id" />
                    <span class="form-hint">Enter the id if you would like to use google recapcha on your forms. you can get an id here.</span>
                </div>

                <div class="form-col">
                    <label for="cloudflare_key">Cloudflare api key *</label>
                    <text-input id="cloudflare_key" v-model="integrations.cloudflare_api_key" />
                    <span class="form-hint">To find your api key visit this link</span>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <label for="nestio_id">Nestio id</label>
                    <text-input id="nestio_id" v-model="integrations.nestio_id" />
                    <span class="form-hint">If you don’t have a nestio id, you can get one here</span>
                </div>
                <div class="form-col">
                    <label for="nestio_key">Nestio api key</label>
                    <text-input id="nestio_key" v-model="integrations.nestio_api_key" />
                    <span class="form-hint">If you don’t have a nestio api key, you can get one here</span>
                </div>
            </div>
            <div class="form-row modal-footer">
                <div class="form-col text-right">
                    <quext-button @click="save" :is-loading="isProcessing">Save</quext-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import QuextButton from "@/components/ui/QuextButton";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import TextInput from '@/components/ui/TextInput';

    export default {
        components: { QuextButton, TextInput },
        mixins: [ ModalNavigation ],
        data: () => {
            return {
                website: {},
                integrations: {
                    cloudflare_api_key: null,
                    recapcha_id: null,
                    nestio_id: null,
                    nestio_api_key: null,
                },
                integrationsAlertId: 'integrations-alert',
                isProcessing: false
            }
        },
        computed: {
            ...mapGetters({
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                updateWebsiteInStore: 'websites/updateWebsite',
                notifySuccess: 'alerts/notifySuccess',
                notifyError: 'alerts/notifyError',
            }),
            save() {
                const payload = {};
                payload.customers_id = this.website.customers_id;
                payload.id = this.website.id;
                payload.integrations = [];

                for (let [key, value] of Object.entries(this.integrations)) {
                    let integrationPieces = key.split('_');
                    if (value) {
                        payload.integrations.push({
                            website_integration_partner_name: integrationPieces[0],
                            integration_parameter_name: key,
                            integration_parameter_value: value
                        });
                    }
                }

                this.isProcessing = true;
                this.$cmsDataProvider.update('updateIntegrationPartners', { websiteId: payload.id, id: '', data: payload })
                .then( (response) => {
                    this.updateWebsiteInStore(response);
                    this.isProcessing = false;
                    this.notifySuccess('The integration information was updated successfully');
                }).catch(() => {
                    this.isProcessing = false;
                    this.notifyError('There was an error saving the information');
                });
            }           
        },
        async mounted() {
            this.website = this.getWebsiteInfo

            this.website.integrations.forEach((integration) => {
                this.integrations[integration.parameter] = integration.value
            })
        },
    }
</script>
